import React from 'react';
import render from 'preact-render-to-string';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import ProductDetail from '../components/ProductDetail';
import IconUsps from '../components/IconUsps';
import Accordion from '../components/Accordion';
import RelatedProducts from '../components/RelatedProducts';
import ModularBlocks from '../components/ModularBlocks';

const ProductPageTemplate = ({
  data: {
    shopifyProduct: {
      shopifyId,
      title,
      description,
      seo,
      mainImages,
      navImages,
      popupImages,
      hasOnlyDefaultVariant,
      options,
      variants,
      descriptionHtml,
      ranges,
      ovenproof,
      dishwasherSafe,
      material,
      colour,
      height,
      width,
      length,
      volume,
      size,
    },
    relatedProducts,
    relatedProductsCategoryFallback,
    relatedProductsAllFallback,
    datoCmsProduct,
    datoCmsProductSitewide: {
      iconUspsInstance,
      accordion: sitewideAccordionItems,
    },
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
  },
}) => {
  const specifications = [
    {
      name: `Range${ranges.length > 1 ? 's' : ''}`,
      value: ranges && ranges.join(', '),
    },
    {
      name: 'Ovenproof',
      value: ovenproof && ovenproof.value,
    },
    {
      name: 'Dishwasher Safe',
      value: dishwasherSafe && dishwasherSafe.value,
    },
    {
      name: 'Material',
      value: material && material.value,
    },
    {
      name: 'Colour',
      value: colour && colour.value,
    },
    {
      name: 'Height',
      value: height && `${height.value}cm`,
    },
    {
      name: 'Width',
      value: width && `${width.value}cm`,
    },
    {
      name: 'Length',
      value: length && `${length.value}cm`,
    },
    {
      name: 'Volume',
      value: volume && `${JSON.parse(volume.value).value}L`,
    },
    {
      name: 'Size',
      value: size && `${size.value}cm`,
    },
  ];

  const specificationsContent = render(
    <ul>
      {specifications.map(
        ({ name, value }) =>
          value && (
            <li>
              <strong>{name}:</strong>{' '}
              {value === 'true' ? 'Yes' : value === 'false' ? 'No' : value}
            </li>
          )
      )}
    </ul>
  );

  const accordionItems = [
    {
      heading: 'Product Description',
      content: descriptionHtml,
    },
    {
      heading: 'Product Specifications',
      content: specificationsContent,
    },
  ];

  const fileDownloadsContent =
    datoCmsProduct &&
    datoCmsProduct.fileDownloads.length > 0 &&
    render(
      <ul>
        {datoCmsProduct.fileDownloads.map(({ url, title }) => (
          <li>
            <a href={url} target="_blank" rel="noopener" download>
              {title}
            </a>
          </li>
        ))}
      </ul>
    );

  if (fileDownloadsContent) {
    accordionItems.push({
      heading: 'Product Downloads',
      content: fileDownloadsContent,
    });
  }

  const relatedProductsSet = [
    ...relatedProducts.nodes,
    ...relatedProductsCategoryFallback.nodes,
    ...relatedProductsAllFallback.nodes,
  ].slice(0, 6);

  return (
    <Layout>
      <HelmetDatoCms>
        <title>{`${seo.title || title}${titleSuffix}`}</title>
        <meta name="description" content={seo.description || description} />
      </HelmetDatoCms>
      <main>
        <ProductDetail
          images={{
            mainImages: mainImages,
            navImages: navImages,
            popupImages: popupImages,
          }}
          shopifyId={shopifyId}
          title={title}
          hasOnlyDefaultVariant={hasOnlyDefaultVariant}
          options={options}
          variants={variants}
        />
        <IconUsps
          items={
            (datoCmsProduct &&
              datoCmsProduct.iconUspsInstance &&
              datoCmsProduct.iconUspsInstance.iconUsps) ||
            (iconUspsInstance && iconUspsInstance.iconUsps)
          }
        />
        <Accordion
          heading="Product Information"
          items={[...accordionItems, ...sitewideAccordionItems]}
          isProductPage={true}
        />
        <RelatedProducts
          heading="Customers also bought"
          items={relatedProductsSet}
        />
        <ModularBlocks items={datoCmsProduct && datoCmsProduct.modularBlocks} />
      </main>
    </Layout>
  );
};

export const ProductPageTemplateQuery = graphql`
  query ProductPageTemplateQuery(
    $handle: String!
    $relatedProducts: [String]
    $firstCollectionId: String
  ) {
    shopifyProduct(handle: { eq: $handle }) {
      shopifyId
      title
      description
      seo {
        title
        description
      }
      mainImages: media {
        preview {
          image {
            gatsbyImageData(width: 560, height: 560)
            altText
          }
        }
      }
      navImages: media {
        preview {
          image {
            gatsbyImageData(width: 75, height: 75)
            altText
          }
        }
      }
      popupImages: media {
        preview {
          image {
            gatsbyImageData(width: 740, height: 740)
            altText
          }
        }
      }
      hasOnlyDefaultVariant
      options {
        name
        values
      }
      variants {
        id
        title
        storefrontId
        shopifyId
        price
        compareAtPrice
        inventoryQuantity
        selectedOptions {
          name
          value
        }
      }
      descriptionHtml
      ranges: tags
      ovenproof: metafield(key: "ovenproof", namespace: "my_fields") {
        value
      }
      dishwasherSafe: metafield(
        key: "dishwasher_safe"
        namespace: "my_fields"
      ) {
        value
      }
      material: metafield(key: "material", namespace: "my_fields") {
        value
      }
      colour: metafield(key: "colour", namespace: "my_fields") {
        value
      }
      height: metafield(key: "height", namespace: "my_fields") {
        value
      }
      width: metafield(key: "width", namespace: "my_fields") {
        value
      }
      length: metafield(key: "length", namespace: "my_fields") {
        value
      }
      volume: metafield(key: "volume", namespace: "my_fields") {
        value
        type
      }
      size: metafield(key: "size", namespace: "my_fields") {
        value
      }
    }
    relatedProducts: allShopifyProduct(
      filter: { handle: { in: $relatedProducts } }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    relatedProductsCategoryFallback: allShopifyProduct(
      filter: {
        handle: { ne: $handle }
        collections: { elemMatch: { id: { eq: $firstCollectionId } } }
      }
      limit: 6
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    relatedProductsAllFallback: allShopifyProduct(
      filter: { handle: { ne: $handle } }
      limit: 6
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    datoCmsProduct(shopifyProduct: { eq: $handle }) {
      iconUspsInstance {
        iconUsps {
          ...IconUspFragment
        }
      }
      fileDownloads {
        url
        title
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...InternalVideoModularBlockFragment
        ...QuoteModularBlockFragment
        ...StatementTextModularBlockFragment
      }
    }
    datoCmsProductSitewide {
      iconUspsInstance {
        iconUsps {
          ...IconUspFragment
        }
      }
      accordion {
        heading
        content
      }
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductPageTemplate;
